

html,
body {
  position: relative;
  height: 100%;
 
}

.box {
    position: relative;
    max-width: 600px;
    width: 100%;
    height: 100%;
   
    box-shadow: 0 0 15px rgba(0,0,0,.1);
  }
  
  /* common */
  .ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 210px;
    padding: 4px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 500 16px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

  .ribbon1 {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon1::before,
  .ribbon1::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #b80b56;
  }
  .ribbon1 span {
    position: absolute;
    display: block;
    width: 210px;
    padding: 4px 0;
    background-color: #b80b56;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 500 16px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

  .ribbon2 {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon2::before,
  .ribbon2::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #e66109;
  }
  .ribbon2 span {
    position: absolute;
    display: block;
    width: 210px;
    padding: 4px 0;
    background-color: #e66109;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 500 16px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }


  .ribbon3 {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon3::before,
  .ribbon3::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2c2b2a;
  }
  .ribbon3 span {
    position: absolute;
    display: block;
    width: 210px;
    padding: 4px 0;
    background-color: #2c2b2a;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 500 16px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
  
  /* top right*/
  .ribbon-top-right {
    top: -5px;
    right: -5px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    text-align: center;
    padding-right: 30px;
    transform: rotate(45deg);
  }
  
  /* bottom left*/
  .ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
  }
  .ribbon-bottom-left::before,
  .ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
  }
  .ribbon-bottom-left::after {
    top: 0;
    left: 0;
  }
  .ribbon-bottom-left span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
  }
  
  /* bottom right*/
  .ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
  }
  .ribbon-bottom-right::before,
  .ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
  }
  .ribbon-bottom-right::after {
    top: 0;
    right: 0;
  }
  .ribbon-bottom-right span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
  }


.mainbg{
    width: auto;
   
  
    height: 100%;
}

.p12{
    width: 731px;
    height: 95px;
    background-image: url("/images/p12.gif");
    background-size:cover;
    background-repeat: no-repeat;
    margin: auto;
   
}
.txt12{
    padding-top: 30px;
    color: lavender;
    font-size: 35px;
}
/*
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
*/
.swiper-container {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  /*height: 300px;*/
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.magexz{
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 30px;
}
.mgcenter{
    max-width: 1024px;
    margin: auto;
    text-align: center;
}

.divbuttom{
    margin: auto;
    text-align: center;
    background-color: rgb(43, 41, 41);
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.menupress {
    display: block;  
    padding: 0;      
    margin: 0;       
    width: 100%;     
  }
  
  .menupress li {
    display: inline-block; 
    width: 33.33%;         
    text-align: center; 
    vertical-align: text-top;
    margin-top: 1px;
    padding-right: 1px;
    padding-left: 1px;
    margin-bottom: 1px;
    color: black;
    font-size: 11px;
   cursor: pointer;
       /* Centre align the images */
  }
  .menupress li img{
      height: 20px;
  }
  .menupress li div{
    width: 59px;
}
.m01 .img{
    width: 70%;
}
.m01 .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 130px;
    /*height: 300px;*/
  }
  .btn-gradlogin {
      cursor: pointer;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: blanchedalmond;
    background-image: linear-gradient(
90deg
,#ca0613 0,#ca0613 51%,#ca0613);
    border-radius: 35px;
    font-size: 30px;
}
.indexpress {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;     /* Get the row full width */
  }
  
  .indexpress li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 2px;
    padding-left: 2px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .indexpress li img{
      width: 80%;
  }
  @media (max-width:768px){
    .mssssx{
        width: 100%;
    }
    .imgpro{
        max-width: 500px;
    }
    .p12{
        width: 500px;
        height: 57px;
        background-image: url("/images/p12.gif");
        background-size:contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        justify-content: center;
    }
    .txt12{
        padding-top: 15px;
        color: lavender;
        font-size: 20px;
        text-align: center;
    }
}
@media (max-width: 500px) {
    .mssssx{
        width: 100%;
    }
    .imgpro{
        max-width: 330px;
    }
    .p12{
        width: 350px;
        height: 57px;
        background-image: url("/images/p12.gif");
        background-size:contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        justify-content: center;
    }
    .txt12{
        padding-top: 9px;
        color: lavender;
        font-size: 20px;
    }
    .indexpress li {
        display: inline-block; /* Get all images to show in a row */
        width: 50%;            /* Show 4 logos per row */
        text-align: center; 
        vertical-align: text-top;
        margin-top: 8px;
        padding-right: 2px;
        padding-left: 2px;
        margin-bottom: 20px;
       
           /* Centre align the images */
      }
}

@media (max-width: 320px) {
    .mssssx{
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .imgpro{
        max-width: 280px;
    }
    .mcasino{
        height: 60%;
        /*width: 90px;*/
    }
    .mainbg{
        max-width: 320px;
        padding-bottom: 10px;
        
    }
    .mgcenter{
   
        max-width: 320px;
     
        
    }
  .p12{
      width: 320px;
      height: 50px;
      background-image: url("/images/p12.gif");
      background-size:contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      justify-content: center;
  }
  .txt12{
      padding-top: 9px;
      color: lavender;
      font-size: 20px;
  }
  .indexpress li {
      display: inline-block; /* Get all images to show in a row */
      width: 50%;            /* Show 4 logos per row */
      text-align: center; 
      vertical-align: text-top;
      margin-top: 8px;
      padding-right: 2px;
      padding-left: 2px;
      margin-bottom: 20px;
     
         /* Centre align the images */
    }
}