/*
html,
body {
  position: relative;
  height: 100%;
  background-color: #fff
}
*/

.mainbg{
    width: auto;
   
}

.p12{
    width: 731px;
    height: 95px;
    background-image: url("/images/p12.gif");
    background-size:cover;
    background-repeat: no-repeat;
    margin: auto;
   
}
.txt12{
    padding-top: 30px;
    color: lavender;
    font-size: 35px;
}
/*
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
*/
.swiper-container {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  /*height: 300px;*/
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.magexz{
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 30px;
}
.mgcenter{
    max-width: 1024px;
    margin: auto;
    text-align: center;
}

.divbuttom{
    margin: auto;
    text-align: center;
    background-color: rgb(43, 41, 41);
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.menupress {
    display: block;  
    padding: 0;      
    margin: 0;       
    width: 100%;     
  }
  
  .menupress li {
    display: inline-block; 
    width: 33.33%;         
    text-align: center; 
    vertical-align: text-top;
    margin-top: 1px;
    padding-right: 1px;
    padding-left: 1px;
    margin-bottom: 1px;
    color: black;
    font-size: 11px;
   cursor: pointer;
       /* Centre align the images */
  }
  .menupress li img{
      height: 20px;
  }
  .menupress li div{
    width: 59px;
}
.m01 .img{
    width: 70%;
}
.m01 .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 130px;
    /*height: 300px;*/
  }
  .btn-gradlogin {
      cursor: pointer;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: blanchedalmond;
    background-image: linear-gradient(
90deg
,#ca0613 0,#ca0613 51%,#ca0613);
    border-radius: 35px;
    font-size: 30px;
}
.indexpress {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;     /* Get the row full width */
  }
  
  .indexpress li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 2px;
    padding-left: 2px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .indexpress li img{
      width: 80%;
  }

@media (max-width: 500px) {
    .p12{
        width: 350px;
        height: 57px;
        background-image: url("/images/p12.gif");
        background-size:contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        justify-content: center;
    }
    .txt12{
        padding-top: 9px;
        color: lavender;
        font-size: 20px;
    }
    .indexpress li {
        display: inline-block; /* Get all images to show in a row */
        width: 50%;            /* Show 4 logos per row */
        text-align: center; 
        vertical-align: text-top;
        margin-top: 8px;
        padding-right: 2px;
        padding-left: 2px;
        margin-bottom: 20px;
       
           /* Centre align the images */
      }
}

@media (max-width: 320px) {
    /*
    .mcasino{
        height: 60%;
      
    }
    .mainbg{
        max-width: 320px;
    }
    .mgcenter{
   
        max-width: 320px;
     
        
    }
    */
  .p12{
      width: 320px;
      height: 50px;
      background-image: url("/images/p12.gif");
      background-size:contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      justify-content: center;
  }
  .txt12{
      padding-top: 9px;
      color: lavender;
      font-size: 20px;
  }
  .indexpress li {
      display: inline-block; /* Get all images to show in a row */
      width: 50%;            /* Show 4 logos per row */
      text-align: center; 
      vertical-align: text-top;
      margin-top: 8px;
      padding-right: 2px;
      padding-left: 2px;
      margin-bottom: 20px;
     
         /* Centre align the images */
    }
}