*{
    font-family: 'Kanit', sans-serif !important;
}
:root {
    --level-1: transparent;
    --level-2:transparent;
    --level-3: #d3f2f3;
    --level-4: #f27c8d;
    --black: rgb(230, 106, 12);
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html, body {min-height:100%;margin:0;}
  body {
    background: rgb(186,184,184);
    background: linear-gradient(234deg, rgba(186,184,184,1) 10%, rgba(207,201,201,1) 48%, rgba(227,227,227,1) 75%);
   
      background-attachment: fixed;
       }
    
    a {
      text-decoration: none;
    }
    .lotto_tap2_full{
      display: inline-block;
      
      text-align: center;
     border: rgb(233, 235, 234) 1px solid;
      border-radius: 10px;
      width: 100%;
      padding: 10px;
      cursor: pointer;
      color: #fff;
    }
    .dtnow{
      font-size: 12px;
      font-weight: normal;
    color: #0785d5;
    }
    .txtremain{
      color: #0ca2a2;
    }
    .divm2play {
      padding: 5px;
      border-radius: 10px;
   
      text-align: center;
      cursor: pointer;
      background-color: #3994f0;
  }
  .divm2aff {
    padding: 5px;
    border-radius: 10px;
   
    text-align: center;
    cursor: pointer;
    background-color: #ed2b7b;
}
.btl_right{
  float: right;
}
.btn_tang{
  padding: 1px;
  background-color: #06bd58;
  cursor: pointer;
  /*
  background: rgb(201,7,61);
  background: linear-gradient(345deg, rgba(201,7,61,1) 9%, rgba(237,8,60,1) 100%);
  */
  border-radius: 5px;
  border: #1799a8 1px solid;
}

    .main_left{
      display:inline-block;
      width: 50%;
      padding: 2px;
    }
    .main_right{
      display:inline-block;
      width: 50%;
      padding: 2px;
    }
    
    button {
      border: #000 !important;
    }

      .flexible-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
      }
      
      .flexible-container embed {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 50%;
      }

  .bglotto{
    border-radius: 15px;
    background: rgb(211,215,213);
    background: linear-gradient(90deg, rgba(211,215,213,1) 24%, rgba(230,237,230,1) 99%);
  }
  .maffdes{
    border-radius: 5px;
    background-color: #f5eee1;
    padding: 10px;
  }
  .sp1{
    color: #d81204;
  }
  .bgthai1{
    background: rgb(41,92,213);
background: linear-gradient(90deg, rgba(41,92,213,1) 24%, rgba(19,74,184,1) 99%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;
    color: #fff;
  }
  .bginter{
    background-color: rgb(8, 72, 212);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;
    color: #fff;
  }
  .bgresult{
    background: rgb(6,92,85);
    background: linear-gradient(306deg, rgba(6,92,85,1) 0%, rgba(44,154,145,1) 100%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;
    color: #fff;
  }
  .bgbaac{
    background: rgb(6,100,51);
    background: linear-gradient(90deg, rgba(6,100,51,1) 24%, rgba(5,133,66,1) 99%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;
    color: #fff;
  }
  .bgthai2{
    background: rgb(237,27,149);
background: linear-gradient(90deg, rgba(237,27,149,1) 24%, rgba(232,15,141,1) 99%);
border-top-left-radius: 15px;
border-top-right-radius: 15px;
padding: 10px;
color: #fff;
  }
  .bglao{
    background: rgb(0,39,104);
background: linear-gradient(90deg, rgba(0,39,104,1) 24%, rgba(0,39,104,1) 99%);
border-top-left-radius: 15px;
border-top-right-radius: 15px;
padding: 10px;
color: #fff;
  }
  .bglotto_bottom{
padding: 7px;
color: #fff;
text-align: left;
  }
  .headlotto{
    padding: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: rgb(180,25,25);
    background: linear-gradient(133deg, rgba(180,25,25,1) 10%, rgba(154,7,7,1) 48%, rgba(166,21,21,1) 75%);
    color: rgb(248, 246, 246);
    text-align: left;
    padding-left: 20px;
     
  }
  .bgboxlotto{
    border-radius: 15px;
    background-color: rgb(18, 163, 182);
  }
  .spanday{
    background-color: rgb(18, 163, 182);
    color: #fff;
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .spanday2{
    background-color: rgb(214, 70, 18);
    color: #fff;
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .bgvn{
    background: rgb(186,17,10);
    background: linear-gradient(90deg, rgba(186,17,10,1) 24%, rgba(180,14,8,1) 99%);
border-top-left-radius: 15px;
border-top-right-radius: 15px;
padding: 10px;
color: #fff;
  }
.lottocontaner_left{
  display: inline-block;
  background: rgb(225,235,233);
background: linear-gradient(90deg, rgba(225,235,233,1) 24%, rgba(204,213,211,1) 99%);
  padding: 5px;
  border-radius: 10px;
  text-align: left;
  width: 30%;
}
.lottocontaner_right{
  display: inline-block;
vertical-align:top;
  text-align: left;
  width: 68%;
}
.totle_lotto_close{
  
  padding: 5px;
}
.lotto_head_close{
  background-color: #e6e4e4;
  padding: 7px;
  color: #ee2727;
font-size: 20px;
  border-radius:5px ;
  margin-bottom: 7px;
 
}
.lotto_btn_close2{
  background-color: #08a3eb;
  padding: 7px;
  color: #f7e6e6;

  border-top-left-radius:5px ;
  border-top-right-radius:5px ;
}
.lotto_btn_close3{
  background-color: #08a3eb;
  padding: 7px;
  color: #f7e6e6;

  border-top-left-radius:5px ;
  border-top-right-radius:5px ;
}
.lotto_btn_close_detail2{
  background-color: #e6e4e4;
  padding: 7px;
  color: #fff;

  border-bottom-left-radius:5px ;
  border-bottom-right-radius:5px ;
  margin-bottom: 4px;
}
.lotto_btn_close{
  background-color: #eec0c0;
  padding: 7px;
  color: #d80c0c;

  border-top-left-radius:5px ;
  border-top-right-radius:5px ;
}
.lotto_btn_close_detail{
  background-color: #e6e4e4;
  padding: 7px;
  color: #fff;

  border-bottom-left-radius:5px ;
  border-bottom-right-radius:5px ;
  margin-bottom: 4px;
}
.numclose{
  color: #fff;
  margin-right: 3px;
  background-color: #f04c40;
  border-radius: 6px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 15px;
  border:0px
}
.numclose2{
  color: #000;
  margin-right: 3px;
  background-color:yellow;
  border-radius: 6px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 15px;
  border:0px
}
.lotto_list{
  border-radius: 5px;
  display: block;
  padding: 5px;
  border:#242626 1px solid;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  background: rgb(152,163,161);
background: linear-gradient(90deg, rgba(152,163,161,1) 24%, rgba(127,135,133,1) 99%);
margin-bottom: 4px;
  
}
.lotto_tang{
  border-radius: 5px;
  background: rgb(111,167,161);
  background: linear-gradient(99deg, rgba(111,167,161,1) 0%, rgba(85,140,134,1) 51%, rgba(85,140,134,1) 100%);
  text-align: center;
  padding: 7px;
 
}
.lotto_tang_list1{
  background-color: rgb(4, 124, 128);
  color: rgb(128, 125, 125);
  padding-left: 5px;
}
.lotto_tang_list1 span{
  float:right;
  width: 80%;
  color: #fff;
  text-align: left;
}
.btn_del_mini{
  text-align: right;
}
.lotto_tang_list2{
  background-color: rgb(6, 94, 97);
  color: rgb(128, 125, 125);
  padding-left: 5px;
}
.lotto_tang_list2 span{
  float:right;
  width: 80%;
  color: #fff;
  text-align: left;
}
.lotto_betlist{
  text-align: center;
  color: rgb(12, 7, 7);
  padding: 3px;
}
.lotto_tap{
  display: block;
  padding-left: 5px;
  width: 100%;
}
.lotto_tap1{
  display: inline-block;
  cursor: pointer;
  color: #fff;
  width: 50%;
  text-align: center;
  border-top-left-radius: 10px;
  border: rgb(249, 250, 249) 1px solid;
  padding: 10px;
}
.lotto_tap span:hover{
  background-color: #ee8b0b;
  transition: 0.4s;
}

.lotto_btn_3{
  display: block;
  border-radius: 5px;
  width: auto;
  border: #221f1f 1px solid;
  background-color: #F50157;
  -webkit-filter: opacity(70%) grayscale(100%);
    filter: opacity(70%) grayscale(100%);
}
.lotto_btn_3_active{
  display: block;
  border-radius: 5px;
  width: auto;
  border: rgb(0, 0, 0) 1px dotted;
  background-color:#F50157;
}
.lotto_btn_3_active_del{
  display: block;
  border-radius: 5px;
  width: auto;
  border: rgb(0, 0, 0) 0px dotted;
  background-color:#fe4848;
}
.lotto_btn_2{
  display: block;
  border-radius: 5px;
  width: auto;
  border: #221f1f 1px solid;
  background-color: #1381e7;
  -webkit-filter: opacity(70%) grayscale(100%);
    filter: opacity(70%) grayscale(100%);
}
.lotto_box_number{
  padding: 8px;
}
.lotto_box_number span{
  display: inline-block;
  width: 50px;
  
  border-radius: 3px;
  border: #171102 1px solid;
  background-color: #fff;
  height: 45px;
  color: #0785d5;
  font-size: 26px;
  
}

.btn_calcula{
  padding: 6px;
  border: #0466a5 1px solid;
  border-radius: 5px;
  text-align: center;
  background-color: #f0f5f7;
  color: #0466a5;
  font-size: 20px;
  cursor: pointer;
}
.btn_random{
  padding: 6px;
  border: #0466a5 1px solid;
  border-radius: 5px;
  text-align: center;
  background-color: #0466a5;
  color: #fff;
  font-size: 20px;cursor: pointer;
}
.btn_cancel{
  padding: 6px;
  border: #2e373a 1px solid;
  border-radius: 5px;
  text-align: center;
  background-color: #798285;
  color: #fff;
  font-size: 18px;cursor: pointer;
}
.btn_del{
  padding: 6px;
  border: #000 1px solid;
  border-radius: 5px;
  text-align: center;
  background-color: #dc0c0c;
  color: #fff;
  font-size: 18px;cursor: pointer;
}
.btn_remove{
  padding: 6px;
  border: #2e373a 1px solid;
  border-radius: 5px;
  text-align: center;
  background-color: #2e373a;
  color: #fff;
  font-size: 18px;cursor: pointer;
}
.btn_bank{
  padding: 6px;
  border: #d6d6da 1px solid;
  border-radius: 5px;
  text-align: center;
  background-color: #d6d6da;
  color: #fff;
  font-size: 18px;cursor: pointer;
}
.MuiChip-colorPrimary{
  background-color: #1381e7 !important;
}
.lotto_check_2{
  background-color: #0785d5;
}
.lotto_btn_2_active{
  display: block;
  border-radius: 5px;
  width: auto;
  border: rgb(0, 0, 0) 1px dotted;
  background-color: #0b66bb;
}
.lotto_btn_2_active_rate{
  display: block;
  border-radius: 5px;
  width: auto;
  border: rgb(0, 0, 0) 0px dotted;
  background-color: #06bd58;
}
.lotto_btn_0{
  display: block;
  border-radius: 5px;
  width: auto;
  border: #221f1f 1px solid;
  background-color: #0cc981;
  -webkit-filter: opacity(70%) grayscale(100%);
  filter: opacity(70%) grayscale(100%);
}
.lotto_btn_0_active{
  display: block;
  border-radius: 5px;
  width: auto;
  border: rgb(0, 0, 0) 1px dotted;
  background-color: #099a62;
}
.showlistselect{
  margin: 0 auto;
  text-align: center;
  padding: 4px;
  background-color: #c3e1f8;
  border-radius: 7px;
  min-height: 45px;
}
.detail_tap1, .detail_tap2{
  margin: 0 auto;
  padding: 5px;
}
.lotto_rate2top{
  display: inline-block;
  cursor: pointer;
  color: #fff;
  width: 70%;
  text-align: center;
  
  padding: 8px;
}
.lotto_rate2top2{
  display: inline-block;
  text-align: center;
  background-color: #d3f2f3;
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
  width: 30%;
  padding: 8px;
  cursor: pointer;
  color: #000;
}
.lotto_rate2top2_active{
  display: inline-block;
  text-align: center;
  background-color: #b44747;
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
  width: 30%;
  padding: 8px;
  cursor: pointer;
  color: #fff;
}
.lotto_rate3top{
  display: inline-block;
  cursor: pointer;
  color: #fff;
  width: 70%;
  text-align: center;
  
  padding: 8px;
}
.lotto_rate3top2{
  display: inline-block;
  text-align: center;
  background-color: #d3f2f3;
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
  width: 30%;
  padding: 8px;
  cursor: pointer;
  color: #000;
}
.lotto_rate3top2_active{
  display: inline-block;
  text-align: center;
  background-color: #b44747;
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
  width: 30%;
  padding: 8px;
  cursor: pointer;
  color: #fff;
}
.active{
  background-color: rgb(245, 129, 21);
  
}
.lotto_press_number_head{
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  text-align: left;
}
.lotto_press_number_head li {
  display: inline-block; /* Get all images to show in a row */
  width: 20%;            /* Show 4 logos per row */
  text-align: left; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 4px;
     /* Centre align the images */
}
.lotto_press_number_3line{
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  text-align: left;
}
.lotto_press_number_3line li {
  display: inline-block; /* Get all images to show in a row */
  width: 10%;            /* Show 4 logos per row */
  text-align: left; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 4px;
     /* Centre align the images */
}
.press_showcheckboxmain{
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  text-align: left;
}
.press_showcheckboxmain li {
  display: inline-block; /* Get all images to show in a row */
  width: 100%;            /* Show 4 logos per row */
  text-align: center; 
  vertical-align: text-top;
  padding-left: 0px;
}

.press_showcheckbox{
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  text-align: left;
}
.press_showcheckbox li {
  display: inline-block; /* Get all images to show in a row */
  width: 10%;            /* Show 4 logos per row */
  text-align: center; 
  vertical-align: text-top;
  border: #979696 1px solid;
  background-color: #fff;
  color: #080707;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.press_showcheckbox li:hover {
  display: inline-block; /* Get all images to show in a row */
  width: 10%;            /* Show 4 logos per row */
  text-align: center; 
  vertical-align: text-top;
  border: #F7377A 1px solid;
  background-color: #F7377A;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.press_showcheckbox li:first-child {
  border-top-left-radius:5px ;
  border-bottom-left-radius:5px ;
}
.press_showcheckbox li:last-child {
  border-top-right-radius:5px ;
  border-bottom-right-radius:5px ;
}
.btn3line{
  display: block;
  width: 100%;
 height: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btnnumber{
  padding: 5px;
  border-radius: 3px;
  background-color: #ecf4fc;
  color: rgb(36, 30, 30);
  border: rgb(75, 72, 72) 1px solid;
  cursor: pointer;
  text-align: center;
}
.btnnumber_close{
  padding: 5px;
  border-radius: 3px;
  background-color: #f50157;
  color: #ecf3f8;
  border: #f50157 1px solid;
  cursor: pointer;
  text-align: center;
}

.btnnumber_paylimit{
  padding: 5px;
  border-radius: 3px;
  background-color: #f1d70b;
  color: #000;
  border: #f50157 1px solid;
  cursor: pointer;
  text-align: center;
}
.btnnumber_active{
  padding: 5px;
  border-radius: 3px;
  background-color: #f7377a;
  color: #fff;
  border: #f50157 1px solid;
  cursor: pointer;
  text-align: center;
}


.btnnumber3{
  padding: 5px;
  border-radius: 3px;
  background-color: #f9fbfd;
  color: #086ac5;
  border: #057dec 1px solid;
  cursor: pointer;
  text-align: center;
}
.btnnumber3_close{
  padding: 5px;
  border-radius: 3px;
  background-color: #d31515;
  color: #ecf3f8;
  border: #0785d5 1px solid;
  cursor: pointer;
  text-align: center;
}

.btnnumber3_paylimit{
  padding: 5px;
  border-radius: 3px;
  background-color: #f1d70b;
  color: #000;
  border: #0785d5 1px solid;
  cursor: pointer;
  text-align: center;
}
.btnnumber3_active{
  padding: 5px;
  border-radius: 3px;
  background-color: #057dec;
  color: #fff;
  border: #057dec 1px solid;
  cursor: pointer;
  text-align: center;
}

.lotto_press_submit {
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  text-align: left;
}

.lotto_press_submit li {
  display: inline-block; /* Get all images to show in a row */
  width: 50%;            /* Show 4 logos per row */
  text-align: left; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 4px;
     /* Centre align the images */
}


.lotto_press_btn {
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  text-align: left;
}

.lotto_press_btn li {
  display: inline-block; /* Get all images to show in a row */
  width: 25%;            /* Show 4 logos per row */
  text-align: left; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 4px;
     /* Centre align the images */
}
.lotto_press {
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%;     /* Get the row full width */
}

.lotto_press li {
  display: inline-block; /* Get all images to show in a row */
  width: 25%;            /* Show 4 logos per row */
  text-align: left; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 4px;
     /* Centre align the images */
}
.lotto_tap2{
  display: inline-block;
  
  text-align: center;
 border: rgb(233, 235, 234) 1px solid;
  border-top-right-radius: 10px;
  width: 50%;
  padding: 10px;
  cursor: pointer;
  color: #fff;
}
.btn_close_box{
  float:right;
}
.rate_main{
  width: 100%;
  padding: 8px;
  display: block;
}
.rate_col1{
display:inline-block;
width: 10%;
}
.rate_col2{
  display:inline-block;
  width: 10%;
  }
  .rate_col3{
    display:inline-block;
    width: 25%;
    }
    .rate_col4{
      display:inline-block;
      width: 10%;
      }
      .rate_col5{
        display:inline-block;
        width: 25%;
        }
        .rate_col6{
          display:inline-block;
          width: 10%;
          }
          .boxlaolottery{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/laolottery.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxlaomidnight{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/laomidnight.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxchaina{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/chaina.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxhanoyplus{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/hanoyplus.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxdownjone{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/downjone.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxnikai{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/nikai.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxtaiwan{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/taiwan.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxkorea{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/korea.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxsingpro{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/singpro.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxindain{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/indain.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxeyip{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/eyip.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxrussai{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/russai.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxengland{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/england1.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxyeraman{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/yeraman.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxmalay{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/malay.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
          .boxchai2{
            display:inline-block;
            float: right;
            height: 44px;
            width: 44px;
            background-image: url("/images/chai21.png");
            background-repeat:no-repeat;
            background-size:contain;
            border-top-right-radius: 10px;
          }
.boxth{
    display:inline-block;
    float: right;
    height: 44px;
    width: 44px;
    background-image: url("/images/th.png");
    background-repeat:no-repeat;
    background-size:contain;
    border-top-right-radius: 10px;
  }
  .boxaom{
    display:inline-block;
    float: right;
    height: 44px;
    width: 44px;
    background-image: url("/images/aom.png");
    background-repeat:no-repeat;
    background-size:contain;
    border-top-right-radius: 10px;
  }
  .boxbaac{
    display:inline-block;
    float: right;
    height: 44px;
    width: 44px;
    background-image: url("/images/baac.png");
    background-repeat:no-repeat;
    background-size:contain;
    border-top-right-radius: 10px;
  }
  .boxlao{
    display:inline-block;
    float: right;
    height: 44px;
    width: 44px;
    background-image: url("/images/lao.png");
    background-repeat:no-repeat;
    background-size:contain;
    border-top-right-radius: 10px;
  }
  .boxvn{
    display:inline-block;
    float: right;
    height: 44px;
    width: 44px;
    background-image: url("/images/vn.png");
    background-repeat:no-repeat;
    background-size:contain;
    border-top-right-radius: 10px;
  }
  .lottorun{
   cursor: pointer;
  }
  .lottoclose{
    -webkit-filter: opacity(70%) grayscale(100%);
    filter: opacity(70%) grayscale(100%);
  }
       .appcountdown{
         width: 100%;
         height: auto;
         display:flex;
         align-items: flex-start;
         justify-content: center;
         padding-top: 15px;
         padding-bottom: 15px;
       
       }
       .appcountdown span{
         margin-left: 5px;
         margin-right: 5px;
         
       }
  .titlelottobet{
    color: #c79004;
    
   font-size: 22px;
  }
  ol {
    list-style: none;
  }
  .headpaddingtop{
    display: block;
    height: 100px;
  }
  body {
    margin: 0px 0 0px;
    text-align: center;
    font-family: "Inter", sans-serif;
  }
  .m7889{
    vertical-align: middle;
    padding-right: 5px;
  }
  .headhome{
    height: 100px;
  }
.mlink{
  text-align: center;
  text-decoration: none;
}
.fixplay{
  width: auto;
  position:fixed;
  z-index: 9999;
  top: 80px;
}
  .btn-gradaff {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: blanchedalmond;
    background: rgb(43,221,202);
    background: linear-gradient(99deg, rgba(43,221,202,1) 0%, rgba(21,205,185,1) 51%, rgba(7,167,150,1) 100%);
  border-radius: 10px;
  font-size: 20px;
}

.bgboxcasino{
  /*background-color: #113031;*/
  background-color: #3f4649;
  height: auto;
}
  .boxsport{
  
    height: auto;
    overflow: hidden;
    background-size: cover;
  }
  .boxsport span{
    justify-items: end;
  }
  .boxsport img{
    width: 40%;
  }
  .mcredit{
   border-bottom: #16a195 1px solid;
    margin: 0 auto;
    width: auto;
  }
  .dashboard{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%; 
    
    list-style: none;
}
.dashboard li{
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    padding-top: 5px;
    padding-bottom: 5px;
   color: #f9fbfd;
   cursor: pointer;
}
.dashboard li.active{
    background-color: #108cd3;
    color: #fff;
}
.vdiomaintxt button{
  color: red;
  font-size: 30px;
}
.vdiomain{
margin: 0 auto;
width: 550px;
height: 300px;
/*
  position: fixed;
  right: 4px;
  bottom: 10px;
  */
}
.vdiomain2{
  margin: 0 auto;
  width: 450px;
  height: 650px;
  z-index: -1111;
  }
  .btl_right{
    float: right;
  }
  
.mmrigt01{
  color: rgb(193, 6, 6) !important
}
.mmrigt02{
  color: #000;
}
.boxdashboard{
  display: block;
  padding: 10px;
  border-radius: 10px;
  border: #1799a8 1px solid;
  
  margin: 5px;
  
}
.dashline{
  display: block;
  padding-bottom: 15px;
  color: #000;
}

  .headaff{
     color: #000;
      border-radius: 10px;
  }
  .container {
    max-width: 1000px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .bgconh1{
    margin: 0 auto;
    width: 100%;
    border-top: #d81414 1px solid;
    padding-top: 7px;
    
  
  }
  .MuiButton-containedSecondary {
    color: #fff;
    background-color: #ad0000 !important;
}
.MuiButton-containedSecondary:hover {
    color: #fff;
    background-color: #c90b0b !important;
}
  .MuiButton-outlinedPrimary {
    color: #3f51b5;
    border: 1px solid rgba(201, 4, 4, 0.5) !important;
}
  .regispro{
    display: block;
    width: "100%";
    padding: 10px;
    
    text-align: left;
    border-radius: 10px;
    border: #000 1px solid;
    color: #000;
    font-weight: normal;
    height: 150px;
    overflow: auto;
    /*background-color: #c79004;*/
  }
  .forgotpass{
    text-align: right;
    padding: 8px;
    width: 100%;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
  }
  .title2 {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid rgb(151, 148, 148); 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
 .title2 span { 
     background:#fff; 
     color: #5b5c5e;
     padding:0 10px; 
 }

 .headsport {
  width: 100%; 
  text-align: center; 
  border-bottom: 2px solid rgb(248, 249, 250); 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

.headsport span { 
    background: rgb(201,7,61);
    background: linear-gradient(345deg, rgba(201,7,61,1) 9%, rgba(237,8,60,1) 100%);
   color: #f1f4f8;
   font-size: 24px;
   padding:0 10px; 
   border-radius: 10px;
}

  .bgcon{
    background: rgb(244,247,252);
      border-radius: 10px;
     width: auto;
  }
  .rectangle {
    position: relative;
    padding: 10px;
    color: #000;
    /*box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);*/
  }
  .sxfoot {
    width: 100%;
    bottom: 0;
    height: 143px;
    position: fixed;
    background-color:#c3e1f8 !important;
    border-top: springgreen 2px solid !important;
}
.mgboby{
  min-width: 250px;
}
.hhtop {
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgb(43, 230, 168);
    background: linear-gradient(345deg, rgb(121, 228, 102) 10%, rgb(31, 221, 18) 48%);
    height: 75px;
    text-align: center;
}
.acbottomac{
  color: #81dec6;
  font-size: 13px;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /*
    background: rgb(36,90,144);
    background: linear-gradient(287deg, rgba(36,90,144,1) 0%, rgba(55,118,190,1) 46%, rgba(81,150,246,1) 100%);
    */
    height: 80px;
    border: 0px solid #57ca7f;
    color: #265d93;
    background-color: #252d30;
   /*
    -webkit-box-shadow: 0px -4px 3px rgba(142, 144, 145, 0.75);
    -moz-box-shadow: 0px -4px 3px rgba(114, 104, 104, 0.75);
    box-shadow: 0px -4px 3px rgba(131, 128, 128, 0.75);
    */
    text-align: center;
    margin-bottom: 0px;
   
  }
  .circle {
    display: inline-table;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    color: floralwhite;
    background-color: rgb(212, 58, 11);
    border-radius: 50%;
  }
  
  .circle__content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  /* LEVEL-1 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .level-1 {
    width: 50%;
    margin: 0 auto 40px;
    background: var(--level-1);
  }
  
  .level-1::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: var(--black);
  }
  .mainrightbar{
    padding-left: 1px;
    padding-right: 1px;
  }
  .mdgin10{
   display: inline-block;
   
    padding: 2px;
    width: 50%;
   
   
   margin: 0 auto;
   
  }
  .divmbox{
    display: block;
    cursor: pointer;
    padding: 2px;
    width: 100%;
    height: auto;
   color: rgb(171, 4, 4);
   border-radius: 5px;
  
   
   margin: 0 auto;
   background: rgb(239,239,239);
background: linear-gradient(234deg, rgba(239,239,239,1) 10%, rgba(221,219,219,1) 48%, rgba(221,219,219,1) 75%);  }
  .mdgin11{
    padding: 5px;
    width: 49%;
    cursor: pointer;
    height: auto;
    display: inline-block;
   color: #fff;
   border-radius: 5px;
 
   
   margin: 0 auto;
   background: rgb(111,167,161);
background: linear-gradient(99deg, rgba(111,167,161,1) 0%, rgba(85,140,134,1) 41%, rgba(64,125,118,1) 100%);
  }
  .mmdmenu{
    float: left;
  }
  .mmdmenu img{
    width: 40px;;
  }
  .dmenu{
    display: block;
    padding: 10px;
    background: rgb(205,52,52);
    background: linear-gradient(234deg, rgba(205,52,52,1) 10%, rgba(196,34,34,1) 48%, rgba(166,21,21,1) 75%);
    color: #fff;
    font-size: 28px;
   text-align: right;
  }
  .dmainmenu{
    background-color: violet;
  }
  
  /* LEVEL-2 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .level-2-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 25%;
    width: 50%;
    height: 2px;
    background: var(--black);
  }
  
  .level-2-wrapper::after {
    display: none;
    content: "";
    position: absolute;
    left: -20px;
    bottom: -20px;
    width: calc(100% + 20px);
    height: 2px;
    background: var(--black);
  }
  
  .level-2-wrapper li {
    position: relative;
  }
  
  .level-2-wrapper > li::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: var(--black);
  }
  
  .level-2 {
    width: 70%;
    margin: 0 auto 40px;
    background: var(--level-2);
  }
  
  .level-2::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: var(--black);
  }
  
  .level-2::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: var(--black);
  }
  
  
  /* LEVEL-3 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .level-3-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    width: 90%;
    margin: 0 auto;
  }
  
  .level-3-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: calc(25% - 5px);
    width: calc(50% + 10px);
    height: 2px;
    background: var(--black);
  }
  
  .level-3-wrapper > li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 2px;
    height: 20px;
    background: var(--black);
  }
  
  .level-3 {
    margin-bottom: 20px;
    background: var(--level-3);
  }
  
  
  /* LEVEL-4 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .level-4-wrapper {
    position: relative;
    width: 80%;
    margin-left: auto;
  }
  
  .level-4-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    width: 2px;
    height: calc(100% + 20px);
    background: var(--black);
  }
  
  .level-4-wrapper li + li {
    margin-top: 20px;
  }
  
  .level-4 {
    font-weight: normal;
    background: var(--level-4);
  }
  
  .level-4::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: var(--black);
  }
  
  
  /* MQ STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  @media screen and (max-width: 700px) {
    .btn_cancel,.btn_del,.btn_remove,.btn_random{
      font-size: 16px;
      height: 44px;
    }
    .rectangle {
      padding: 20px 10px;
    }
  .hmobile{
      display: none;
  }
  :root {
    --level-1: transparent;
    --black: rgb(244,247,252);;
   
  }
    .level-1,
    .level-2 {
      width: 100%;
    }
  
    .level-1 {
      margin-bottom: 10px;
    }
  
    .level-1::before,
    .level-2-wrapper > li::before {
      display: none;
    }
    
    .level-2-wrapper,
    .level-2-wrapper::after,
    .level-2::after {
      display: block;
    }
  
    .level-2-wrapper {
      width: 90%;
      margin-left: 10%;
    }
  
    .level-2-wrapper::before {
      left: -20px;
      width: 2px;
      height: calc(100% + 40px);
    }
  
    .level-2-wrapper > li:not(:first-child) {
      margin-top: 30px;
    }
  }
  
  
  /* FOOTER
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .page-footer {
    position: fixed;
    right: 0;
    bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .page-footer a {
    margin-left: 4px;
  }
.lineaff{
    text-align: right;
  color: #fff;
}
.mssssx{
    color: azure;
    border-radius: 20px;
    
    width: 80%;
    margin: 0 auto;
    padding-bottom: 30px;
    font-size: 16px;
}
video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .aff1{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #e8a608;
    text-align: center;
    font-size: 18px;
}
.aff2loss{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #f8446e;
    text-align: center;
    font-size: 22px;
}
.aff2win{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #08ce93;
    
    text-align: center;
    font-size: 22px;
}
.aff1h{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #e8a608;
    text-align: center;
    font-size: 18px;
    border: #e8a608 1px solid;
}
.affline{
    width: 100%;
    border-bottom:#1280e7 2px solid;
}
.afflink{
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(227, 242, 243);
    color: #000;
}
.afflinkac{
    padding: 10px;
    border-radius: 10px;
    color: crimson;
    background-color: rgb(245, 243, 219);
    border: coral 1px solid;
}
.affbg{
  /*display: block;*/
  padding: 10px;
  background-color: #ebe6d5;
  border-radius: 10px;
  margin: 10px;
  width: auto;
  border: #f2ecec 1px solid;
}
.affbgacc{
  padding: 10px;
  border: #05c2cf 1px solid;
  background-color: #f9cc38;
  border-radius: 10px;
  margin: 10px;
  width: auto;
}
.aff{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%; 
    
    list-style: none;
}
.aff li{
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
   cursor: pointer;
   border-top: #a5060e 2px solid;
}
.aff li.active{
  background: rgb(180,25,25);
  background: linear-gradient(234deg, rgba(180,25,25,1) 10%, rgba(196,34,34,1) 48%, rgba(166,21,21,1) 75%);
  color: #fff;
}

.poy{
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%; 
  
  list-style: none;
}
.poy li{
  display: inline-block; /* Get all images to show in a row */
  width: 33.33%;            /* Show 4 logos per row */
  text-align: center; 
  vertical-align: text-top;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgb(17, 112, 104);
 cursor: pointer;
 border-top: #3ca59d 2px solid;
}
.poy li:first-child{
border-top-left-radius:10px ;
}
.poy li:last-child{
  border-top-right-radius:10px ;
  }
.poy li.active{
  background-color: #05b8a9e5;
  color: #fff;
}

.divbink{
    animation-duration: 400ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    color: red;
 }
.headtitle{
  color: #d8030e;
  text-align: center;
}
 /* start load*/
 .mload{
     margin: auto;
     text-align: center;
     color: #dec30c;
 }
 .cssload-container *, .cssload-container *:before, .cssload-container *:after{
	box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
}

.cssload-container {
	margin: 49px auto;
	position: relative;
	width: 97px;
	height: 97px;
}
.cssload-container > div {
	float: left;
	background: rgb(185,108,255);
	height: 100%;
	width: 5px;
	margin-right: 1px;
	display: inline-block;
}

.cssload-container {
	position: relative;
	width: 97px;
	height: 97px;
}
.cssload-container > div {
	background: transparent;
	border: 8px solid transparent;
	border-color: rgb(185,108,255) transparent;
	border-radius: 100%;
		-o-border-radius: 100%;
		-ms-border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate"("-50%, -50%")";
		-o-transform: translate"("-50%, -50%")";
		-ms-transform: translate"("-50%, -50%")";
		-webkit-transform: translate"("-50%, -50%")";
		-moz-transform: translate"("-50%, -50%")";
	transform: translate(-50%, -50%) rotate(0);
		-o-transform: translate(-50%, -50%) rotate(0);
		-ms-transform: translate(-50%, -50%) rotate(0);
		-webkit-transform: translate(-50%, -50%) rotate(0);
		-moz-transform: translate(-50%, -50%) rotate(0);
	animation: cssload-wave 2.3s infinite ease-in-out;
		-o-animation: cssload-wave 2.3s infinite ease-in-out;
		-ms-animation: cssload-wave 2.3s infinite ease-in-out;
		-webkit-animation: cssload-wave 2.3s infinite ease-in-out;
		-moz-animation: cssload-wave 2.3s infinite ease-in-out;
}
.cssload-container .cssload-shaft1 {
	animation-delay: 0.12s;
		-o-animation-delay: 0.12s;
		-ms-animation-delay: 0.12s;
		-webkit-animation-delay: 0.12s;
		-moz-animation-delay: 0.12s;
	width: 19px;
	height: 19px;
}
.cssload-container .cssload-shaft2 {
	animation-delay: 0.23s;
		-o-animation-delay: 0.23s;
		-ms-animation-delay: 0.23s;
		-webkit-animation-delay: 0.23s;
		-moz-animation-delay: 0.23s;
	width: 24px;
	height: 24px;
}
.cssload-container .cssload-shaft3 {
	animation-delay: 0.35s;
		-o-animation-delay: 0.35s;
		-ms-animation-delay: 0.35s;
		-webkit-animation-delay: 0.35s;
		-moz-animation-delay: 0.35s;
	width: 34px;
	height: 34px;
}
.cssload-container .cssload-shaft4 {
	animation-delay: 0.46s;
		-o-animation-delay: 0.46s;
		-ms-animation-delay: 0.46s;
		-webkit-animation-delay: 0.46s;
		-moz-animation-delay: 0.46s;
	width: 44px;
	height: 44px;
}
.cssload-container .cssload-shaft5 {
	animation-delay: 0.58s;
		-o-animation-delay: 0.58s;
		-ms-animation-delay: 0.58s;
		-webkit-animation-delay: 0.58s;
		-moz-animation-delay: 0.58s;
	width: 54px;
	height: 54px;
}
.cssload-container .cssload-shaft6 {
	animation-delay: 0.69s;
		-o-animation-delay: 0.69s;
		-ms-animation-delay: 0.69s;
		-webkit-animation-delay: 0.69s;
		-moz-animation-delay: 0.69s;
	width: 63px;
	height: 63px;
}
.cssload-container .cssload-shaft7 {
	animation-delay: 0.81s;
		-o-animation-delay: 0.81s;
		-ms-animation-delay: 0.81s;
		-webkit-animation-delay: 0.81s;
		-moz-animation-delay: 0.81s;
	width: 73px;
	height: 73px;
}
.cssload-container .cssload-shaft8 {
	animation-delay: 0.92s;
		-o-animation-delay: 0.92s;
		-ms-animation-delay: 0.92s;
		-webkit-animation-delay: 0.92s;
		-moz-animation-delay: 0.92s;
	width: 78px;
	height: 78px;
}
.cssload-container .cssload-shaft9 {
	animation-delay: 1.04s;
		-o-animation-delay: 1.04s;
		-ms-animation-delay: 1.04s;
		-webkit-animation-delay: 1.04s;
		-moz-animation-delay: 1.04s;
	width: 83px;
	height: 83px;
}
.cssload-container .cssload-shaft10 {
	animation-delay: 1.15s;
		-o-animation-delay: 1.15s;
		-ms-animation-delay: 1.15s;
		-webkit-animation-delay: 1.15s;
		-moz-animation-delay: 1.15s;
	width: 88px;
	height: 88px;
}



@keyframes cssload-wave {
	50% {
		transform: translate(-50%, -50%) rotate(360deg);
		border-color: rgb(86,215,198) transparent;
	}
}

@-o-keyframes cssload-wave {
	50% {
		-o-transform: translate(-50%, -50%) rotate(360deg);
		border-color: rgb(86,215,198) transparent;
	}
}

@-ms-keyframes cssload-wave {
	50% {
		-ms-transform: translate(-50%, -50%) rotate(360deg);
		border-color: rgb(86,215,198) transparent;
	}
}

@-webkit-keyframes cssload-wave {
	50% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		border-color: rgb(86,215,198) transparent;
	}
}

@-moz-keyframes cssload-wave {
	50% {
		-moz-transform: translate(-50%, -50%) rotate(360deg);
		border-color: rgb(86,215,198) transparent;
	}
}
 /* end load*/
 @keyframes blink {
    from {
       opacity: 1;
    }
    to {
       opacity: 0.3;
    }
  }
  
  .MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px solid rgba(0, 0, 0, 0.12) !important;
}
.MuiDrawer-paperAnchorDockedRight {
    border-right: 0px solid rgba(0, 0, 0, 0.12) !important;
}
.MuiDrawer-paperAnchorLeft {
    left: 0;
    right: auto;
    background-color: #289b70 !important;
}
.MuiDrawer-paperAnchorRight {
    left: auto;
    right: 0;
    /*
    background: rgb(17,140,130);
background: linear-gradient(99deg, rgba(17,140,130,1) 0%, rgba(2,68,62,1) 100%);
*/
}
  .pressitem {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%; 
    text-align: left;    /* Get the row full width */
  }
  
  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 25%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  
  .pressitem li img{
    max-width: 200px;
    border-radius: 10px;
  }
  .pressitem li img:hover{
    opacity: 0.8;
  }
  .ufooter2{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;   
    background-color: #142b2f;
  }
  .ufooter2 li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 18px;
    padding-left: 8px;
  
   
       /* Centre align the images */
  }

  .ufooter{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;   
    background: rgb(150,5,5);
    background: linear-gradient(133deg, rgba(150,5,5,1) 10%, rgba(142,9,9,1) 48%, rgba(127,4,4,1) 75%);
  }
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 18px;
    padding-left: 8px;
  
   
       /* Centre align the images */
  }

  .listhome{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;   
   
  }
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 340px;
    cursor: pointer;
  }

  .acbuttom{
    /*background-color: #55bba1;*/
    border: #05c2cf 1px dotted;
    background: rgb(211, 30, 18);
    background: linear-gradient(157deg, rgb(238, 47, 33) 0%, rgb(201, 39, 18) 46%, rgb(168, 16, 5) 100%);
    padding-bottom: 1px;
    width: 50%;
    margin: auto;
    border-radius: 10px;
  }
  .acbuttom div{
    color: #fff;
  }
  .ufooter li img{
    max-height: 46px;
    cursor: pointer;
  }
  /*
  .ufooter li{
    color: #81dec6;
    font-size: 13px;
  }
  */
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 13px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
  .boxacc{
    display: block;
    padding: 25px;
    border: #d2e0f0 1px solid;
    border-radius: 10px;
    background-color: #f9fbfd;
  }
  .boxaccleft{
    width: 30%;
    display:inline-block;
    padding: 10px;
text-align: right;

  }
  .boxaccright{
    width: 70%;
    padding: 10px;
    text-align: left;
    display: inline-block;
    font-size: 17px;
    color: rgb(245, 62, 17);
  }

  .hfooter{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;   
  }
  .hfooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;     
    text-align: center;       /* Show 4 logos per row */
   
    
    margin-top: 4px;
    padding-right: 8px;
    padding-left: 4px;
   padding-top: 8px;
    vertical-align: middle;
   
       /* Centre align the images */
  }
  .hfooter li img{
    max-height: 62px;
    cursor: pointer;
  }
.mverti{
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.phome2{
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%;   
}
.phome2 li {
  display: inline-block; /* Get all images to show in a row */
  width: 33.33%;            /* Show 4 logos per row */
  text-align: left; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 8px;
  padding-left: 4px;
  margin-bottom: 10px;
  color: #000;
font-size: 16px;
}

  .phome{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;   
  }
  .phome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 8px;
    padding-left: 8px;
    margin-bottom: 10px;
    color: #000;
   
  }
  .phomelast{
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;   
  }
  .phomelast li {
    display: inline-block; /* Get all images to show in a row */
    width: 100%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 8px;
    padding-left: 8px;
    margin-bottom: 10px;
    color: #000000;
   
  }
  
  .phome li .divm{ 
    display: block;
    width: 98%;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    /*border:#30d5e7 1px solid;*/
  
    
    color: #fff;}
    .phome li .divm:hover{ 
      display: block;
      width: 98%;
      text-align: center;
      border-radius: 10px;
    
     color: rgb(61, 240, 231);
      transition: 0.4s;
    
    }
  .phome li img{
    margin-top: 0px;
    max-height: 62px;
  }

  .press {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;  
    text-align: left;
  }
  
  .press li {
    display: inline-block; /* Get all images to show in a row */
    width: 33%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 18px;
    padding-left: 8px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .reward_contain{
    padding: 0px;
  }
  .reward_head{
    padding: 5px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: #057dec 1px solid;
    background-color: #0785d5;
    color: #fff;
    text-align: center;
  }
  .reward_head2{
    padding: 5px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: #057dec 1px solid;
    border-right: #057dec 1px solid;
    border-left: #057dec 1px solid;
    background-color: #e5f0f7;
    color: rgb(46, 38, 38);
    text-align: center;
  }
  .reward_head3{
    
height: 35px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: #5b5d5f 1px solid;
    border-top: #5b5d5f 1px solid;
    border-right: #5b5d5f 1px solid;
    border-left: #5b5d5f 1px solid;
    background-color: #fff;
    color: rgb(46, 38, 38);
    text-align: left;
  }
  .divleft{
    display:inline-block;
    color: #000;
    width: 50%;
    text-align: right;
    font-size: 14px;
  }
  .divleft_contain2{
    padding-left: 5px;
  }
  .divleft_contain{
    padding: 10px;
    background-color: #fafacb;
    border-right: rgb(92, 87, 86) 1px solid;
  
    
  }
  .divleft_headx{
    padding-bottom: 5px;
    border-bottom: #cfc1a4 1px solid;
  }
  .divno{
    font-size: 20px;
    color: dodgerblue;
  }
  .divleft_contain_c{
   
    background-color: #e2e2e1;
    border-right: rgb(199, 196, 195) 1px solid;
    text-align: center;
    border-radius: 10px;
    
  }
  .divright{
    display:inline-block;
    color: #000;
    width: 50%;
    text-align: left;
    font-size: 14px;
  }
  .span001{
    padding-top: 8px;
    padding-left: 4px;
    font-size: 14px;
    display:inline-block;
    float: left;
  }
  .dvv{
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #fac720;
    display:inline-block;
    float: right;
    color: #000;
    
    height: 33px;
   font-size: 14px;
    border-top-right-radius: 10px;
  }
  .dvv4{
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #8d8d8a;
    display:inline-block;
    float: right;
    color: rgb(253, 249, 249);
    
    height: 33px;
   font-size: 14px;
    border-top-right-radius: 10px;
  }
  .dvv2{
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #1db055;
    display:inline-block;
    float: right;
    color: rgb(253, 249, 249);
    
    height: 33px;
   font-size: 14px;
    border-top-right-radius: 10px;
  }
  .dvv3{
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #c02d09;
    display:inline-block;
    float: right;
    color: rgb(253, 249, 249);
    
    height: 33px;
   font-size: 14px;
    border-top-right-radius: 10px;
  }
  .reward_body2{
    padding: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: #057dec 1px solid;
    border-right: #057dec 1px solid;
    border-left: #057dec 1px solid;
    text-align: center;
    background-color: #fff;
    display: block;
    height: auto;
  }
  .reward_body2_contain{
    display: block;
    height: auto;
    padding: 1px;
    clear: both;
  }
  .span001x{
    display:inline-block;
    color: #000;
    float: right;
    clear: both;
    font-size: 14px;
  }
  .mline3{
    vertical-align: middle;
    font-size: 14px;
  }
  .divbuttom233{
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
    color: #000;
    text-align: left;
    border-top:rgb(197, 195, 195) 1px solid;
  }
  .reward_body{
    padding: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: #057dec 1px solid;
    text-align: center;
    background-color: #f3f0ea;
  }
  .press1 {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;  
    text-align: left;
  }
  
  .press1 li {
    display: inline-block; /* Get all images to show in a row */
    width: 100%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
   
       /* Centre align the images */
  }

  .press2 {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;  
    text-align: left;
  }
  
  .press2 li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
   
       /* Centre align the images */
  }
  .press3 {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;  
    text-align: left;
  }
  
  .press3 li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
   
       /* Centre align the images */
  }
  /*
  .press li img{
      max-width: 150px;
  }
  */
  .pressmenu {
    display: block;  /* Remove bullet points; allow greater control of positioning */
    padding: 0;      /* Override defaults for lists */
    margin: 0;       /* Override defaults for lists */
    width: 100%;     /* Get the row full width */
   
   border-bottom-left-radius: 20px;
   border-top-right-radius: 20px;
   border: rgb(18, 163, 182) 1px solid;
   /*border-radius: 20px;*/
  }
  
  .pressmenu li {
    display: inline-block; /* Get all images to show in a row */
    width: 25%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 5px;
    /*background-image: linear-gradient(0deg, rgba(212, 37, 116, 1) 0%, rgba(157, 6, 87, 1) 100%);*/
    /*background-image: linear-gradient(0deg, rgba(8, 125, 86, 1) 0%, rgba(11, 56, 47, 1) 100%);*/
   
       /* Centre align the images */
  }
  .pressmenu li img{
    max-width: 100px;
}
  .pressmenu li:hover{
    display: inline-block; /* Get all images to show in a row */
    width: 25%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 0px;
    padding-right: 2px;
    padding-left: 2px;
    margin-bottom: 0px;
    cursor: pointer;
    background-color:#1799a8;
    
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: 0.4s;
   /*background-color: #0466a5;*/
    /*background-image: linear-gradient(0deg, rgba(212, 37, 116, 1) 69%, rgba(238, 225, 47, 1) 100%);*/
    
  }
  .topcontain{
    display: block;
    margin: auto;
    max-width: 912px;
   
    border-radius: 15px;
    border: rgb(167, 170, 173) 1px solid;
    box-shadow: 0 3px 10px rgba(221, 226, 226, 0.6);
    
  }
  .divm2{
    
    padding: 5px;
    border: rgb(4, 143, 97) 1px dotted;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(221, 226, 226, 0.6);
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    }
  .divm2 div{
    color: #046c5a;
    font-size: 20px;
  }

  .divm3{
    
    padding: 5px;
    border-radius: 7px;
    color: #b80303;
    /*
    box-shadow: 0 3px 8px rgba(8, 8, 8, 0.4);
    */
    border:#b80303 1px dotted;
    /*
    background: rgb(239,239,239);
    background: linear-gradient(234deg, rgba(239,239,239,1) 10%, rgba(221,219,219,1) 48%, rgba(221,219,219,1) 75%);
    */
    text-align: center;
    cursor: pointer;
  }
  .topcontainwhit{
    display: block;
    margin: auto;
    max-width: 912px;
    border-radius: 10px;
   /* background-color: #fff; */
  }
  .topcontainx{
    display: block;
    margin: auto;
    max-width: 912px;
    
    
    border-radius: 10px;
    
  }
  .topcontainpd{
    padding: 0px;
  }
  .topcontain_left{
    padding: 10px;
   
    width: 25%;
    display: inline-block;
    vertical-align: middle;
  }
  .topcontain_center{
    padding: 10px;
   text-align: center;
    width: 40%;
    display: inline-block;
  }
  .topcontain_right{
    padding: 10px;
   text-align: left;
    width: 25%;
    display: inline-block;
    vertical-align: middle;
  }
  .xribbon2{
   
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #d82c1f;
    margin: auto;
    width: 35%;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  .xribbonlotto{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #d82c1f;
    margin: auto;
    width: 35%;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  .divheadcasino{
    background: rgb(6,92,85);
    background: linear-gradient(
306deg, rgba(6,92,85,1) 0%, rgba(44,154,145,1) 100%);
      text-align: center;
      width: '100%';
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 20px;
      text-transform: uppercase;
      color: #fff;

  }
  .divbgcasino{
      /*background-color:#3e4446;*/
      padding: 1px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
   
  }
  
  .divtextcasinoh{
    text-align: center;
    font-size: 17px;
    color: white;
}
.divLoading{
   
    position: fixed; /* or absolute */
    top: 50%;
    left: 45%;
    background-color: #000000;
    width: '100%';
    height: 40px;
    border-radius: 10px;
}​
.bgcredit{
    background-color: #c79004;
    height: 50px;
    border-radius: 10px;
    color: #08ce93;
}
.pcreditmoney{
    text-align: center;color: rgb(187, 18, 18);
    display: inline-block;
    padding: 10px;
}
.pcreditmoney2{
    text-align: center;color: #b80303;
    display: inline-block;
}

.divcredit{
    z-index: 1;
    top: 66px;
  
    left: 0;
    position: fixed;
    background-color: #050505;
   /*border: #c90b0b 1px solid;*/
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 3px;
    color: #fff;
    cursor: pointer;
}
.divLoadingfix{
   
    position: fixed; /* or absolute */
    top: 200px;
    left: 45%;
    background-color: #000000;
    width: '100%';
    height: 40px;
    border-radius: 10px;
}​
.imgcasino{
    background-size:auto;
    max-width: 100px;
    
}
.divlogo1{
    text-align: center;
}
.imgcasinoitem{
    background-size: cover;
    max-width: 100px;
    border-radius: 10px;
}
.imgcasinoh{
    background-size: cover;
    max-width: 180px;
}
.mmlogo{
  padding: 0px;
  margin: 0px;
  
 
}
.divlogoclose{
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
filter: grayscale(100%);
 border-radius: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /*background-color: #000;*/
  padding: 0px;
  /*opacity: 0.8;*/
  /*border: tan 1px solid;*/
  text-align: center;
  width: 280px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  transition: transform .4s; 
}
.pgloss{
  padding-top: 15px;
}
.imgold{
  border-radius: 50%;
  border: #fff 1px solid;
}
  .divlogo{
   
   border-radius: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /*background-color: #000;*/
    padding: 0px;
    /*opacity: 0.8;*/
    /*border: tan 1px solid;*/
    text-align: center;
    width: 280px;
    box-shadow: rgb(31, 33, 36) 20px 30px 30px -20px;
    transition: transform .4s; 
    background-image: linear-gradient(169deg, #0430a0, #031d60), linear-gradient(180deg, #a78059 -30%, #f2d7a9 22%, #715236 72%, #f1bb73 112%);
  }

  .divlogo:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
      cursor: pointer;
      transform: scale(1.07);
  transform-origin: 50% 50%;
  }

  .divtextcasino{
    text-align: center;
    font-size: 17px;
    color: #fff;
    /*background-color: #141717;*/
    width: 280px;
}
.divtextcasinoitem{
  text-align: center;
  font-size: 17px;
  color: #fff;
  /*background-color: #141717;*/
 
}

  .navbar {
    z-index: 1;
    border: 0;
    border-radius: 0;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.navbar, .navbar>.container, .navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

  .MuiBottomNavigationAction-label {
    opacity: 1;
    font-size: 16px !important;
    color:#000;
    font-weight: normal;
    transition: font-size 0.2s, opacity 0.2s;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
    transition-delay: 0.1s;
}

.imgbank{
    vertical-align: middle;
    border-radius: 50%;
    float: right;
}

.imgprofile{
    vertical-align: middle;
    border-radius: 50%;
    border: #dec30c 2px solid;
}
.imgpro{
  max-width: 500px;
}
.imgth{
    vertical-align: middle;
    border-radius: 50%;
  
}
.maff{
    max-width: 100%;
}
.titlemain{
    color: beige;
    font-size: 16px;
    background-color: cadetblue;
}
.pright{
  float: right;
}
.bglinedot{
  border-bottom:#444141 1px solid !important;
  color: #000 !important;
}
  .bgboxprofile{
      display: block;
     width: 100%;
     margin: 0 auto;
      border-radius: 10px;
      padding: 5px;
      border-top:1px solid #000;     
  }
  
.ListItemTextCss{
   
    max-width: "50%";
  background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 3px;
    border-radius:10px
}
  .inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.MuiBottomNavigationAction-root.Mui-selected {
    color: #dec30c !important;
    padding-top: 6px;
}
.roundedCorner
{
    margin: 0 auto;
    width:135px;height:135px;
    
    background-color:#0baab6;
    border: #258faa 4px solid;
    border-radius:100px;
    color: rgb(233, 6, 6);
    font-weight: 400;
    font-size: '20px' !important;
    /*background-image: url('/images/circle_credit_green333.png');*/

    background-position: center;
background-size: cover;
background-repeat: no-repeat;
    
}
.bgtextchat{
    border-radius: 10px !important;
}
.bgsticker{
    position: fixed;
    z-index: 999999;
    bottom: 0px;
    background-color: #efefef;
    height: 104px;
    width: 100%;
    overflow-y: auto;
}
.ulsticker{
    display: inline-block;
    list-style: none;
    /*margin: 3px;*/
}
.ulsticker li{
    display: inline-flex;
    justify-self:baseline;
    padding-left: 10px;
}
.gifphoto {
    height: 100px;
    max-width: 50%;
    min-height: 24px;
    width: auto;
    border-radius: 18px 18px 18px 18px;
}
.bgmsgbody{
    display: block;
    /*height: auto;*/
    /*height: 200px;*/
    top: 60px;
    bottom: 160px;
    position: fixed;
    width: 100%;
    /*background-color: #b7bbbb; */
    background: transparent;
    overflow: auto;
}

.bgmsg{
    display: block;
    height: 70px;
    bottom: 90px;
    position: fixed;
    width: 100%;
    background-color: #2494a8;
}
.title {
    font-size: 15px;
    margin: 0 0 10px;
    padding: 0 0 10px;
    position: relative;
    color: rgb(105, 108, 121);
}
.title::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: rgb(199, 196, 196);
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 1;
}

.title::after {
    background-color: #efefef; 
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translate(-50%,0);
    width: 50px;
}
.MuiBadge-anchorOriginTopRightRectangle {
    top: -10;
    right: -15 !important;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}

.bgindex{
    height: 100vh;
    background-image: url('/images/bgall.png');

    background-position: center bottom;
background-size: cover;
background-repeat: no-repeat;
background-color: #181414;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #c38519 !important;
}

.MuiDialog-paperFullScreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    background-color: #fff !important;
}
.btnnew{
  display: block;
  padding: 8px;
  border:#000 1px solid;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #221f1f;
}
.btnnewbonusx{
  display: block;
  padding: 8px;
  border:#000 1px solid;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #fc4c4c;
}
.btnnew3{
  display: block;
  padding: 8px;
  border-bottom:#0785d5 1px solid;
 font-size: 19px;
  text-align: left;
  color: #09bcbf;
}
.btnnewbonus{
  display: inline-block;
  padding: 5px;
  border-bottom:#ef0712 1px solid;
 font-size: 16px;
  text-align: left;
  color: #ef0712;
  
  border-top: #ef0712 1p solid;
}
.bnxm{
  float:right;
  color: #0c569b;
  background-color: #e6f3fb;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.bnxm2{
  width: 100%;
  color: #0c569b;
  background-color: #e6f3fb;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  
}
.btnshowcredit{
  display: block;
  padding: 8px;
  border: rgb(19, 99, 238) 1px solid;
  border-radius: 10px;
  text-align: center;
  color: #f8446e;
  cursor: pointer;
  background-color: #c79004;
}
.btnnew:hover{
  background-color: #19829c;
  border: rgb(162, 160, 160) 1px solid;
  transition: 0.6s;
}
.datano{
  display: block;
  margin: 0 auto;
  height: 60px;
  width: 100%;
  text-align: center;
  padding-top: 20px;

 
}
.btnback{
    background:transparent !important;
    border: #000 1px solid !important;
    color: #000 !important;
}
.btnback:hover{
    border: rgb(236, 31, 4) 1px solid !important;
}
.mqrcode{
  background-color: #57ca7f;
  padding: 8px;
  border-radius: 9px;
  margin-bottom: 9px;
  color: #fff;
}
.btndeposit{
  display: block;
  padding: 5px;
  border:#41b1be 1px solid;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  background: rgb(3,159,146);
  background: linear-gradient(306deg, rgba(3,159,146,1) 0%, rgba(22,204,189,1) 100%);
}


.bgheadmenu{
    display: block;
    padding: 5px;
    border:#fff 1px solid;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    width: 98%;
    margin: 0 auto;
    font-size: 16px;
    background: rgb(180,25,25);
  background: linear-gradient(234deg, rgba(180,25,25,1) 10%, rgba(196,34,34,1) 48%, rgba(166,21,21,1) 75%);
  }
  .btnwithdraw{
    display: block;
    padding: 5px;
    border:#d81204 1px solid;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    background: rgb(211, 30, 18);
  background: linear-gradient(157deg, rgb(238, 47, 33) 0%, rgb(201, 39, 18) 46%, rgb(168, 16, 5) 100%);
  }
  .btnsubmit{
    display: block;
    padding: 10px;
    border:#f0f2f5 1px solid;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    background: rgb(211, 30, 18);
  background: linear-gradient(157deg, rgb(238, 47, 33) 0%, rgb(201, 39, 18) 46%, rgb(168, 16, 5) 100%);
  }
  .btnsubmit:hover{
      border: #001308 1px solid;
      background: rgb(242, 47, 33);
      background: linear-gradient(157deg, rgb(238, 47, 33) 0%, rgb(201, 39, 18) 46%, rgb(168, 16, 5) 100%);
    transition: 0.4s;
  }
  .btnsubmit2{
    display: block;
    padding: 8px;
    border: rgb(219, 28, 3) 1px solid;
    border-radius: 10px;
    text-align: center;
    color: rgb(219, 28, 3);
    cursor: pointer;
    font-size: 18px;
   
  }
  .btnsubmit3{
    display: block;
    padding: 8px;
    border: rgb(21, 22, 22) 1px solid;
    border-radius: 10px;
    text-align: center;
    color: rgb(242, 245, 245);
    cursor: pointer;
    font-size: 18px;
    background: rgb(246,46,13);
  background: linear-gradient(61deg, rgba(246,46,13,1) 20%, rgba(255,0,63,1) 35%, rgba(255,0,24,1) 100%);
  }
.btnsubmit4{
  margin: 0 auto;
  width: 55%;
  display: block;
  padding: 8px;
  border: rgb(21, 22, 22) 1px solid;
  border-radius: 10px;
  text-align: center;
  color: rgb(242, 245, 245);
  cursor: pointer;
  font-size: 18px;
  background: rgb(31,205,252);
background: linear-gradient(61deg, rgba(31,205,252,1) 20%, rgba(25,187,231,1) 35%, rgba(12,186,233,1) 100%);}
.btnsubmit5{
  margin: 0 auto;
  width: 55%;
  display: block;
  padding: 8px;
  border: rgb(21, 22, 22) 1px solid;
  border-radius: 10px;
  text-align: center;
  color: rgb(242, 245, 245);
  cursor: pointer;
  font-size: 18px;
  background: rgb(13,246,126);
background: linear-gradient(61deg, rgba(13,246,126,1) 20%, rgba(15,205,101,1) 35%, rgba(15,205,101,1) 100%);}
.btnsubmit2:hover{
  border: rgb(112, 229, 241) 1px solid;
  transition: 0.4s;
}

.btnsubmit44{
  margin: 0 auto;
  
  display: block;
  padding: 8px;
  border: rgb(21, 22, 22) 1px solid;
  border-radius: 10px;
  text-align: center;
  color: rgb(242, 245, 245);
  cursor: pointer;
  font-size: 18px;
  background: rgb(31,205,252);
background: linear-gradient(61deg, rgba(31,205,252,1) 20%, rgba(25,187,231,1) 35%, rgba(12,186,233,1) 100%);}
.btnsubmit55{
  margin: 0 auto;
  
  display: block;
  padding: 8px;
  border: rgb(21, 22, 22) 1px solid;
  border-radius: 10px;
  text-align: center;
  color: rgb(242, 245, 245);
  cursor: pointer;
  font-size: 18px;
  background: rgb(13,246,126);
background: linear-gradient(61deg, rgba(13,246,126,1) 20%, rgba(15,205,101,1) 35%, rgba(15,205,101,1) 100%);}
.btnsubmit2:hover{
  border: rgb(112, 229, 241) 1px solid;
  transition: 0.4s;
}
.btnnone{
  display: none;
}
.form__input {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 17px;
	margin: 0 auto;
  padding: 10px;
  padding-left: 20px;
  background-color: #fff;
  border:#000 1px solid;
border-radius: 5px;
  width: 100%;
  display: block;
  transition: all 0.3s;
}
.form__input2 {
  font-family: 'Roboto', sans-serif;
  color: rgb(223, 27, 27);
  font-size: 22px;
	margin: 0 auto;
  padding: 5px;
  padding-left: 20px;
  background-color: rgb(7, 6, 6);
  border:#000 1px solid;
border-radius: 5px;
  width: 100%;
  display: block;
  text-align: right;
  transition: all 0.3s;
}
::-webkit-input-placeholder { /* Edge */
  color: #dfcc05;font-weight: normal;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #dfcc05;
  font-weight: normal;
}

::placeholder {
  color: rgb(75, 75, 75);
  font-size: 15px;
}
.bgline{
    font-size: 16px;
    color: #000;
    border-bottom: #cfc1a4 0px solid;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: left;
}
.pcredit{
    padding-top: 45px;
   
    margin: 0 auto;
    color: #fff;
}

.MuiButton-containedSecondary {
    color: #fff;
    background-color: #ad0000 !important;
}
.MuiButton-containedSecondary:hover {
    color: #fff;
    background-color: #c90b0b !important;
}
.MuiInputBase-root {
    color: #d4ab59 !important;
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem !important;
    box-sizing: border-box;
    border: 1px #d4ab59 solid !important;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
}
.MuiInputBase-root:focus{
    border: 1px #d4ab59 solid !important;
}
.MuiInputBase-root:active{
    border: 1px #d4ab59 solid !important;
}

.MuiInputBase-root:visited{
    border: 1px #d4ab59 solid !important;
}

.MuiFormLabel-root {
    color: #d4ab59 !important;
    padding: 0;
    font-size: 1.1rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}
.forgot{
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
}
.MuiInputBase-input {
    font: inherit;
    color: #d4ab59 !important;
    font-size: 18px !important;
    width: 100%;
    border: 0;
    height: 1.1876em;
    border-radius: 5px !important;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 5;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: initial !important;
    
}

.col-3 input {
  width: 230px;
}
.col-5 input {
  width: 230px;
}

.col-1 {
  flex-basis: 20px;
  padding-left: 0px;
  font-size: 12px;
  padding-top: 8px;
}
.col-2 {
  flex-basis: 50px;
  padding-left: 3px;
  padding-right: 3px;
  color: #fff;
}


.col-4 {
  flex-basis: 60px;
 font-size: 14px;
 padding-top: 5px;
}

  .col-6 {
    flex-basis: 35px;
 
  }

.lotto_btn_3_active_del{
  height: 35px;width: 35px;
  padding-top: 2px;
  padding-left: 3px;
  color: #fff;
  cursor: pointer;
}
.lotto_btn_2_active_rate{
  height: 35px;width: 55px;padding-top: 5px;
  font-size: 14px;color: #fff;
}

.hcol-1 {
  flex-basis: 60px;
  padding-left: 0px;
  font-size: 12px;
  padding-top: 8px;
}
.hcol-2 {
  flex-basis: 400px;
  padding-left: 3px;
  padding-right: 3px;
  color:red;
  text-align: left;
}

.hcol-3 {
  flex-basis: 110px;
 font-size: 14px;text-align: center;
 color: #0466a5;
 
}
  .hcol-4 {
    flex-basis: 335px;
  }

@media (max-width: 980px) {
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 310px;
  }
  .col-3 input {
    width: 230px;
  }
  .col-5 input {
    width: 230px;
  }

  .col-1 {
    flex-basis: 20px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 60px;
   font-size: 14px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }

  .hcol-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .hcol-2 {
    flex-basis: 320px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
    text-align: left;
  }
 
  .hcol-3 {
    flex-basis: 100px;
   font-size: 14px;
   padding-top: 5px;
  }
    .hcol-4 {
      flex-basis: 280px;
    }
}

@media (max-width: 890px) {
  .divlogo{
    width: 230px;
  }
  .divtextcasino{
    width: 230px;
    text-align: center;
  }
.acbuttom{
  width: 70%;
}

.listhome li {
  display: inline-block; /* Get all images to show in a row */
  width: 50%;            /* Show 4 logos per row */
  text-align: center; 
  vertical-align: text-top;
  margin-top: 4px;
  padding-right: 2px;
  padding-left: 2px;
}
.listhome li img{
  border-radius: 10px;
  max-width: 280px;
}

  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 200px;
    border-radius: 10px;
  }
  .col-3 input {
    width: 230px;
  }
  .col-5 input {
    width: 230px;
  }

  .col-1 {
    flex-basis: 20px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 60px;
   font-size: 14px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }

  .hcol-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 5px;
   padding-bottom: 5px;
  }
  .hcol-2 {
    flex-basis: 250px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }
 
  .hcol-3 {
    flex-basis: 140px;
   font-size: 14px;
   padding-top: 5px;
   padding-bottom: 5px;
  }
    .hcol-4 {
      flex-basis: 205px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
}
@media (max-width: 820px) {
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 270px;
  }
  .col-3 input {
    width: 190px;
  }
  .col-5 input {
    width: 190px;
  }

  .col-1 {
    flex-basis: 20px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 60px;
   font-size: 14px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }

  .hcol-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .hcol-2 {
    flex-basis: 250px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }
 
  .hcol-3 {
    flex-basis: 130px;
   font-size: 14px;
   padding-top: 5px;
   padding-bottom: 5px;
  }
    .hcol-4 {
      flex-basis: 195px; padding-top: 5px;
      padding-bottom: 5px;
    }
}
@media (max-width: 780px) {
  .press2 li {
    display: inline-block; /* Get all images to show in a row */
    width: 100%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
   
       /* Centre align the images */
  }
  .btn_cancel,.btn_del,.btn_remove,.btn_random{
    font-size: 14px;
    height: 44px;
  }
  .lotto_press li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 4px;
       /* Centre align the images */
  }
  .divlogo{
    width: 200px;
  }
  .divtextcasino{
    width: auto;
    text-align: center;
  }
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 2px;
    padding-right: 1px;
    padding-left: 1px;
  }
  .acbuttom{
    width: 80%;
  }
  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 170px;
    border-radius: 10px;
  }

  .press li {
      display: inline-block; 
      width: 50%;            
      text-align: center; 
      vertical-align: text-top;
      margin-top: 8px;
      padding-right: 8px;
      padding-left: 2px;
      margin-bottom: 20px;
    }
  
  .divtextcasinoh{
      font-size: 14px;
  }
  .divcredit{
      z-index: 1;
      top: 56px;
    
      left: 0;
      position: fixed;
      background-color: #080707;
      opacity: 0.8;
     /*border: #c90b0b 1px solid;*/
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 4px;
      font-size: 16px;
      color: rgb(241, 218, 206);
  }
  .col-3 input {
    width: 160px;
  }
  .col-5 input {
    width: 160px;
  }

  .col-1 {
    flex-basis: 20px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 60px;
   font-size: 14px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }

  .hcol-1 {
    flex-basis: 34px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .hcol-2 {
    flex-basis: 250px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
  }
 
  .hcol-3 {
    flex-basis: 100px;
   font-size: 14px;
   padding-top: 5px;
  }
    .hcol-4 {
      flex-basis: 250px;
    }
}

@media (max-width: 550px) {
  .vdiomain{
    width: 500px;
    height: 240px;
    }
    .vdiomain2{
      margin: 0 auto;
      width: 320px;
      height: 550px;
      }
  .btn_cancel,.btn_del,.btn_remove,.btn_random{
    font-size: 13px;
    height: 44px;
  }
  .lotto_press li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 4px;
       /* Centre align the images */
  }
  .xribbonlotto{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #d82c1f;
    margin: auto;
    width: 55%;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  .phome2 li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 8px;
    padding-left: 4px;
    margin-bottom: 10px;
    color: #101111;
  font-size: 13px;
  }
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 18px;
    padding-left: 8px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 230px;
  }
  .topcontain_left{
    padding: 10px;
   
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  
  .topcontain_right{
    padding: 10px;
   text-align: left;
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  .divlogo{
    width: 200px;
  }
  
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 2px;
    padding-right: 1px;
    padding-left: 1px;
  }
.acbuttom{
  width: 97%;
}
  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 33.33%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 140px;
    border-radius: 10px;
  }
  .col-3 input {
    width: 130px;
  }
  .col-5 input {
    width: 130px;
  }

  .col-1 {
    flex-basis: 20px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 60px;
   font-size: 11px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }

  .hcol-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 2px;
  }
  .hcol-2 {
    flex-basis: 200px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
  }
 
  .hcol-3 {
    flex-basis: 100px;
   font-size: 13px;
   padding-top: 5px;
   padding-bottom: 5px;
  }
    .hcol-4 {
      flex-basis: 155px;
    }
}

@media (max-width: 500px) {
  .vdiomain{
    width: 440px;
    height: 210px;
    }
    .vdiomain2{
      margin: 0 auto;
      width: 320px;
      height: 550px;
      }
  .lotto_press li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 4px;
       /* Centre align the images */
  }
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 18px;
    padding-left: 8px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 180px;
  }
  .divlogo{
    width: 180px;
  }
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 2px;
    padding-right: 3px;
    padding-left: 3px;
  }
.acbuttom{
  width: 90%;
}
  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 190px;
    border-radius: 10px;
  }
    .press li {
        display: inline-block; /* Get all images to show in a row */
        width: 50%;            /* Show 4 logos per row */
        text-align: center; 
        vertical-align: text-top;
        margin-top: 8px;
        padding-right: 8px;
        padding-left: 2px;
        margin-bottom: 20px;
       
           /* Centre align the images */
      }
    
    .divtextcasinoh{
        font-size: 14px;
    }
    .divcredit{
        z-index: 1;
        top: 56px;
      
        left: 0;
        position: fixed;
        background-color: #080707;
        opacity: 0.8;
       /*border: #c90b0b 1px solid;*/
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 4px;
        font-size: 16px;
        color: rgb(241, 218, 206);
    }

    .col-3 input {
      width: 110px;
    }
    .col-5 input {
      width: 110px;
    }
  
    .col-1 {
      flex-basis: 20px;
      padding-left: 0px;
      font-size: 12px;
      padding-top: 8px;
    }
    .col-2 {
      flex-basis: 50px;
      padding-left: 3px;
      padding-right: 3px;
      color: #fff;
    }
   
  
    .col-4 {
      flex-basis: 60px;
     font-size: 11px;
     padding-top: 5px;
    }
    
      .col-6 {
        flex-basis: 35px;
     
      }
  
    .lotto_btn_3_active_del{
      height: 35px;width: 35px;
      padding-top: 2px;
      padding-left: 3px;
      color: #fff;
      cursor: pointer;
    }
    .lotto_btn_2_active_rate{
      height: 35px;width: 55px;padding-top: 5px;
      font-size: 14px;color: #fff;
    }
  
    .hcol-1 {
      flex-basis: 24px;
      padding-left: 0px;
      font-size: 12px;
      padding-top: 5px;
   padding-bottom: 5px;
    }
    .hcol-2 {
      flex-basis: 190px;
      padding-left: 3px;
      padding-right: 3px;
      color:red;
      padding-top: 5px;
      padding-bottom: 5px;
    }
   
    .hcol-3 {
      flex-basis: 110px;
     font-size: 12px;
     padding-top: 5px;
     padding-bottom: 5px;
    }
      .hcol-4 {
        flex-basis: 145px;
      }
}

@media (max-width: 470px) {
  .vdiomain{
    width: 400px;
    height: 190px;
    }
    .vdiomain2{
      margin: 0 auto;
      width: 320px;
      height: 550px;
      }
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 18px;
    padding-left: 8px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 155px;
  }
  .divlogo{
    width: 160px;
  }
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 2px;
    padding-right: 1px;
    padding-left: 1px;
  }
.acbuttom{
  width: 95%;
}
  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 170px;
    border-radius: 10px;
  }

  .pressmenu li img{
    max-width: 80px;
    max-height: 60px;
}
  .press li {
      display: inline-block; /* Get all images to show in a row */
      width: 50%;            /* Show 4 logos per row */
      text-align: center; 
      vertical-align: text-top;
      margin-top: 8px;
      padding-right: 8px;
      padding-left: 2px;
      margin-bottom: 20px;
     
         /* Centre align the images */
    }
  
  .divtextcasinoh{
      font-size: 14px;
  }
  .divcredit{
      z-index: 1;
      top: 56px;
    
      left: 0;
      position: fixed;
      background-color: #080707;
      opacity: 0.8;
     /*border: #c90b0b 1px solid;*/
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 4px;
      font-size: 16px;
      color: rgb(241, 218, 206);
  }
  .col-3 input {
    width: 110px;
  }
  .col-5 input {
    width: 110px;
  }

  .col-1 {
    flex-basis: 20px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 60px;
   font-size: 11px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }

  .hcol-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 5px;
   padding-bottom: 5px;
  }
  .hcol-2 {
    flex-basis: 180px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
    padding-top: 5px;
    padding-bottom: 5px;
  }
 
  .hcol-3 {
    flex-basis: 100px;
   font-size: 12px;
   padding-top: 5px;
   padding-bottom: 5px;
  }
    .hcol-4 {
      flex-basis: 135px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .lotto_press_number_3line li {
      display: inline-block; /* Get all images to show in a row */
      width: 20%;            /* Show 4 logos per row */
      text-align: left; 
      vertical-align: text-top;
      margin-top: 4px;
      padding-right: 4px;
         /* Centre align the images */
    }
    .lotto_tang_list1 span{
      float:right;
      width: 60%;
      color: #fff;
      text-align: left;
    }
    .lotto_tang_list2 span{
      float:right;
      width: 60%;
      color: #fff;
      text-align: left;
    }
    .lotto_rate3top2 {
      display: inline-block;
      text-align: center;
      background-color: #d3f2f3;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      width: 30%;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 2px;
      cursor: pointer;
      color: #000;
  }

}

@media (max-width: 400px) {
  .vdiomain{
    width: 340px;
    height: 160px;
    }
    .vdiomain2{
      margin: 0 auto;
      width: 320px;
      height: 550px;
      }
  .divm2x img {
    width: 130px;
  }
  .lotto_tang_list1 span{
    float:right;
    width: 60%;
    color: #fff;
    text-align: left;
  }
  .lotto_tang_list2 span{
    float:right;
    width: 60%;
    color: #fff;
    text-align: left;
  }
  .hcol-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 5px;
      padding-bottom: 5px;
  }
  .hcol-2 {
    flex-basis: 200px;
    padding-left: 3px;
    padding-right: 3px;
    color:red;
    padding-top: 5px;
    padding-bottom: 5px;
  }
 
  .hcol-3 {
    flex-basis: 80px;
   
   padding-top: 5px;
   padding-bottom: 5px;
  }
    .hcol-4 {
      flex-basis: 135px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

  .col-3 input {
    width: 80px;
  }
  .col-5 input {
    width: 80px;
  }

  .col-1 {
    flex-basis: 24px;
    padding-left: 0px;
    font-size: 12px;
    padding-top: 8px;
  }
  .col-2 {
    flex-basis: 50px;
    padding-left: 3px;
    padding-right: 3px;
    color: #fff;
  }
 

  .col-4 {
    flex-basis: 40px;
   font-size: 11px;
   padding-top: 5px;
  }
  
    .col-6 {
      flex-basis: 35px;
   
    }

  .lotto_btn_3_active_del{
    height: 35px;width: 35px;
    padding-top: 2px;
    padding-left: 3px;
    color: #fff;
    cursor: pointer;
  }
  .lotto_btn_2_active_rate{
    height: 35px;width: 55px;padding-top: 5px;
    font-size: 14px;color: #fff;
  }
  .btn_cancel,.btn_del,.btn_remove,.btn_random{
    font-size: 12px;
    height: 44px;
  }
  .lotto_btn_3, .lotto_btn_3_active,.lotto_btn_2, .lotto_btn_2_active,.lotto_btn_0, .lotto_btn_0_active{
    font-size: 12px;
  }
  .lotto_press li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 4px;
       /* Centre align the images */
  }
  .divlogo{
    width: 160px;
  }
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 2px;
    padding-right: 1px;
    padding-left: 1px;
  }

  .acbuttom{
    width: 100%;
  }
  .headhome{
    height: 80px;
  }
  .pcredit{
    padding-top: 25px;
   
    margin: 0 auto;
    color: #fff;
}
  .roundedCorner
  {
      margin: 0 auto;
      width:130px;height:130px;
      
      background-color:#000000;
      border: #258faa 2px solid;
      border-radius:100px;
      color: rgb(6, 233, 127);
      font-weight: 400;
      font-size: '20px' !important;
      /*background-image: url('/images/circle_credit_green333.png');*/
  
      background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
      
  }
  
  .phome li .divm{ 
    font-size: 13px;
    display: block;
    width: 98%;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    
    color: #fff;}

  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 150px;
    border-radius: 5px;
  }

  .pressmenu li img{
    max-width: 80px;
    max-height: 60px;
}
  .press li {
      display: inline-block; /* Get all images to show in a row */
      width: 50%;            /* Show 4 logos per row */
      text-align: center; 
      vertical-align: text-top;
      margin-top: 8px;
      padding-right: 8px;
      padding-left: 2px;
      margin-bottom: 20px;
     
         /* Centre align the images */
    }
  
  .divtextcasinoh{
      font-size: 14px;
  }
  .divcredit{
      z-index: 1;
      top: 56px;
    
      left: 0;
      position: fixed;
      background-color: #080707;
      opacity: 0.8;
     /*border: #c90b0b 1px solid;*/
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 4px;
      font-size: 16px;
      color: rgb(241, 218, 206);
  }
  .boxaccleft{
    width: 40%;
    display:inline-block;
    padding: 10px;
text-align: right;
font-size: 13px;

  }
  .boxaccright{
    width: 60%;
    padding: 10px;
    text-align: left;
    display: inline-block;
    font-size: 13px;
    color: rgb(245, 62, 17);
  }
  .lotto_press_number_3line li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 4px;
       /* Centre align the images */
  }
  .btnnumber3_active,.btnnumber3,.btnnumber3_close,.btnnumber3_paylimit{
    font-size: 14px;
  }
}

@media (max-width: 364px) {
  .vdiomain{
  width: 300px;
  height: 120px;
  }
  .vdiomain2{
    margin: 0 auto;
    width: 320px;
    height: 550px;
    }
  .imgpro{
    max-width: 280px;
  }
  .lotto_press_number_3line li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 4px;
       /* Centre align the images */
  }
  .listhome li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 4px;
    padding-right: 18px;
    padding-left: 8px;
  }
  .listhome li img{
    border-radius: 10px;
    max-width: 125px;
  }
  .divlogo{
    width: 140px;
  }
  .ufooter li {
    display: inline-block; /* Get all images to show in a row */
    width: 20%;            /* Show 4 logos per row */
    text-align: center; 
    vertical-align: text-top;
    margin-top: 2px;
    padding-right: 1px;
    padding-left: 1px;
  }
  .acbuttom{
    width: 100%;
  }
  .pressitem li {
    display: inline-block; /* Get all images to show in a row */
    width: 50%;            /* Show 4 logos per row */
    text-align: left; 
    vertical-align: text-top;
    margin-top: 8px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
   
       /* Centre align the images */
  }
  .pressitem li img{
    max-width: 130px;
    border-radius: 5px;
  }

  .pressmenu li img{
    max-width: 80px;
    max-height: 60px;
}
  .press li {
      display: inline-block; /* Get all images to show in a row */
      width: 50%;            /* Show 4 logos per row */
      text-align: center; 
      vertical-align: text-top;
      margin-top: 8px;
      padding-right: 8px;
      padding-left: 2px;
      margin-bottom: 20px;
     
         /* Centre align the images */
    }
  
  .divtextcasinoh{
      font-size: 14px;
  }
  .divcredit{
      z-index: 1;
      top: 56px;
    
      left: 0;
      position: fixed;
      background-color: #080707;
      opacity: 0.8;
     /*border: #c90b0b 1px solid;*/
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 4px;
      font-size: 16px;
      color: rgb(241, 218, 206);
  }
  .boxaccleft{
    width: 40%;
    display:inline-block;
    padding: 10px;
text-align: right;
font-size: 12px;

  }
  .boxaccright{
    width: 60%;
    padding: 10px;
    text-align: left;
    display: inline-block;
    font-size: 12px;
    color: rgb(245, 62, 17);
  }
}