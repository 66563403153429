.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
   
  }

  .txtnumber{
      border-radius: 4px;
      padding: 5px;
      width: auto;
  }
  
  .responsive-table li {
    
      border-radius: 3px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 3px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
    }
    .table-header {
      background-color: #f3f6f7;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .table-row {
      background-color: #fffbfb;
      box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
      padding-bottom: 3px;
    }
    .table-row-red {
        background-color: #f58181;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        padding-bottom: 3px;
      }
    .col-1 {
      flex-basis: 50px;
      padding-left: 3px;
    }
    .col-2 {
      flex-basis: 100px;
      padding-left: 3px;
      padding-right: 3px;
     
      color:red;
    }
    .col-3 {
      flex-basis: 200px;
      padding-left: 3px;
    }

    .col-4 {
      flex-basis: 100px;
      padding-left: 3px;
    }
    .col-5 {
        flex-basis: 200px;
        padding-left: 3px;
      }
      .col-6 {
        flex-basis: 60px;
        padding-left: 3px;
      }
    

  